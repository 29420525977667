.main-bar {
  opacity: 0;
  background-color: red;
  width: 200px;
  height: 200px;
  display: block;
  cursor: pointer;
}
input[type='button'] {
  opacity: 0;
  background-color: red;
  width: 200px;
  height: 200px;
  display: block;
  cursor: pointer;
}
#input-area {
  cursor: pointer;
  width: 200px;
  height: 200px;
  margin-top: -200px;
  text-align: center;
  justify-content: center;
  padding: 60px 30px;
  color: #2372b9;
}

#detail-area {
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  text-align: left;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(255, 255, 255, 0.3);
}

#detail-area h4,
p {
  margin: 8px;
}

#input-area > i {
  font-size: 50px;
}

button {
  display: block;
}

.container {
  position: relative;
  width: 200px;
  height: 200px;
  border: solid 3px #2372b9;
  border-radius: 10px;
  margin: 10px !important;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: 0.5s ease;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  text-align: center;
}

.container:hover .image {
  opacity: 1;
}

.container:hover .middle {
  opacity: 1;
}

.text {
  background-color: #2372b9;
  width: fit-content;
  color: white;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px;
  margin: 4px;
  border: 0px;
  display: inline-block;
  text-decoration: none;
}

.detail-text {
  background-color: #2372b9;
  width: 150px !important;
  color: white;
  opacity: 1;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px;
  margin: 4px;
  border: 0px;
  display: inline-block;
  text-decoration: none;
}

.text:hover {
  background-color: #1e609b;
}

.file-type {
  margin-top: 10px;
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  font: normal normal 600 18px/24px Open Sans;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  text-overflow: ellipsis;
}

.file-info-name {
  font: normal normal normal 12px/22px Open Sans;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  text-align: center;
  width: 200px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-info-date {
  text-align: left;
  font: normal normal normal 11px/22px Open Sans;
  letter-spacing: 0px;
  width: 200px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #646669;
  opacity: 1;
}

.backButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: 0px;
  color: white;
}

.backButton:hover {
  color: #edf4fa;
}
