.root {
  --dk-gray: #646669;
  --dark-gray: #222222;
}

.sep-label {
  text-align: left;
  font: normal normal normal 12px/14px Open Sans;
  letter-spacing: 0px;
  color: #646669;
  opacity: 1;
  margin-bottom: 6px;
  white-space: nowrap;
}

.sep-data {
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--dark-gray);
  opacity: 1;
  text-transform: capitalize;
}

.review-label {
  text-align: left;
  font: normal normal normal 12px/14px Open Sans;
  letter-spacing: 0px;
  color: #646669;
  opacity: 1;
  margin-bottom: 6px;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.review-data {
  color: #222;
  font: normal normal normal 14px/22px Open Sans;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sep-progress-label {
  font: normal normal normal 10px/10px Open Sans;
  color: #646669;
  letter-spacing: 0px;
  opacity: 1;
  text-align: left;
  margin: 0px;
}

.sep-progress-num {
  letter-spacing: 0px;
  color: var(--dark-gray);
  text-align: left;
  font: normal normal 600 10px/10px Open Sans;
  letter-spacing: 0px;
  margin: 0px;
}

.circle {
  width: 12px;
  height: 12px;
  /* UI Properties */
  background: #007fa4 0% 0% no-repeat padding-box;
  border: 4px solid #ffffff;
  opacity: 1;
  display: inline;
  white-space: nowrap;
}

.task {
  text-align: left;
  font: normal normal normal 10px/22px Open Sans;
  letter-spacing: 0px;
  color: #222222;
}

.tasks-info {
  text-align: center;
  font: normal normal normal 20px/27px Open Sans;
  letter-spacing: 0px;
  color: #7e8083;
  opacity: 1;
}

.showInfo {
  display: flex;
  flex-direction: row;
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  color: var(--dark-gray);
  opacity: 1;
  margin: 8px;
  text-transform: capitalize;
}

.sep-description {
  text-align: left;
  font: normal normal normal 14px/22px Open Sans;
  letter-spacing: 0px;
  margin: 0px 8px;
  color: #222222;
  opacity: 1;
}
