.task-icon {
  color: #2372b9;
}

.normal-text {
  font-size: 12px;
}

.blue-text {
  font-size: 12px;
  color: #2372b9 !important;
  text-decoration: none;
}

.blue-text > p {
  margin: 0px !important;
  width: 250px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}

.blue-text > div {
  margin: 0px !important;
  width: 250px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
}
