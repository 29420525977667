body {
  margin: 0;
}
/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.quill {
  border: 1px solid #7e8083;
  border-radius: 6px;
  background-color: white;
  max-height: 600px;
  overflow: auto;
}

.knockout-description a {
  text-decoration: none;
  color: #2372b9;
  font-weight: 600;
}

.required:after {
  content: ' *';
  color: red;
}

/* total width */
div::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
div::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
div::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
div::-webkit-scrollbar-button {
  display: none;
}
