.datafield {
  /* Set the initial shadow styles */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

.datafield:hover {
  /* Set the shadow styles on hover */
  box-shadow: -1px 5px 8px rgba(158, 156, 156, 0.8);
  margin-bottom: 1px;
}

.dragging {
  /* Set the shadow styles on datafield dragging */
  background-color: #edf4f9;
}
