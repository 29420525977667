.icon-button {
  position: relative;
  padding: 10px;
  width: 150px;
  height: 32px;
}

.icon-button > i {
  position: absolute;
  font-size: 14px;
  left: 10px;
  transition: all 0.25s ease-out;
}

.icon-button > p {
  position: absolute;
  font-size: 14px;
  font-weight: bold;
  transition: all 0.25s ease-out;
}

.asc {
  right: 15px;
}

.desc {
  right: 10px;
}

.icon-button:hover > i {
  left: 115px;
}

.icon-button:hover > p {
  right: 30px;
}

.hovered-icon {
  color: '#2372B9';
}
